import React from 'react';
import { graphql } from 'gatsby';

import Latest from './latest';

const IndexPage = ({ data }) => {
  return <Latest />;
};

export const query = graphql`
  query IndexPageQuery {
    allSanityAuthor {
      edges {
        node {
          name
          surname
        }
      }
    }
  }
`;

export default IndexPage;
