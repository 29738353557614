import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const renderItem = ({ node }) => {
  const { id, title, category, slug, description } = node;
  return (
    <Link
      key={id}
      to={`/page/${slug?.current}`}
      className="font-sans border border-gray-400 rounded-lg md:mt-0 p-4"
    >
      <div className="uppercase tracking-wide text-sm text-indigo-600 font-bold">
        {category?.title}
      </div>
      <div className="block mt-1 text-lg leading-tight font-semibold text-gray-900">
        {title}
      </div>
      <p className="mt-2 text-gray-600">{description}</p>
    </Link>
  );
};

const Latest = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPage(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            title
            category {
              title
            }
            slug {
              current
            }
            description
          }
        }
      }
    }
  `);

  const { allSanityPage } = data || {};
  return (
    <Layout>
      <SEO title="Latest Posts" description="Latest Posts of jsbible.com." />
      <h1 className="text-2xl mb-2">Latest Posts</h1>
      <div className="grid sm:grid-cols-1 row-gap-2 md:grid-cols-2 gap-2">
        {allSanityPage?.edges.map(renderItem)}
      </div>
    </Layout>
  );
};

export default Latest;
